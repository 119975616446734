<script>
import axios from 'axios'
import { ref, computed } from "vue";
import { useCookies } from "vue3-cookies";
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";

export default {
  name: "brandSearchForm",
  props: [
    'ID',
    'type',
    'data',
    'urlPut',
    'urlGet',
  ],
  components:{
   
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();   
    let tk = 'Bearer '+cookies.get('tk')
    let searchTerm = ref('')
    let qSearch = ''
    let countries = []


    const fullSearch = async () =>
    {
      axios.get(`${serverConfig.EP}brands/search/full/text`,
        { 
          params: { 
            q: qSearch,
           
          },
          headers: { 'authorization':tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          countries = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }


    const searchCountries = computed(() => {
      if (searchTerm.value === '') {
        return []
      }
      let matches = 0
      return countries.filter(country => {
        if (country.name.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
          matches++
          return country
        }
      })
    });
    const selectCountry = (country) => {
      selectedCountry.value = country
      searchTerm.value = ''
    }
    let selectedCountry = ref('')

    return { 
      fullSearch,
      searchTerm,
      searchCountries,
      selectCountry,
      selectedCountry,
      active, 
      toggleActive,
      cookies,
      tk 
    };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      fileStorage:[],
      uploadValue:0,
      fileList:null,
      fileData:[],
      file:'',
      bucket:'',
      dataEdit:{},
      qSearch:'',
      resultsList:[],
      open:false,
    }
  },
  mounted(){
   

  },
  methods:{
    async fullSearch2(){
      axios.get(`${this.UrlServer}brands/search/full/text`,
        { 
          params: { 
            q: this.qSearch,
          },
          headers: { 'authorization':this.tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          this.resultsList = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    selectItem(params){
      this.updateItem(params)
      this.setCookie()
      this.open = false
    },
    updateItem(params){
      let data = {}
      data.marcaID = params._id
      data.CodMarca = params.code 
      data.marca = params.name
      axios.put(`${this.urlPut}`, data, {headers: { authorization:this.tk}} )
        .then((response)=>{
          if (response.data.acknowledged==true) {
            this.$emit('getParent')
          }
        })
        .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
              console.log(error)
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }     
        });
    },
    openModal(){
        document.getElementById("editmodal").click();
    },
    closeModal(){
      document.getElementById("close-modal").click();
    },
    showDiv(){
        if (this.open == true){
            this.open = false
        } else {
            this.open = true
        }
    },
    setCookie(){
      this.cookies.set('brand-search-form', this.ID)
    },
    removeCookie(){
      this.cookies.remove('brand-search-form')
    },
  }
};
</script>
<template>
  <a href="javascript:void(0)"  @click="showDiv">
    <template v-if="open==false"><i class="ri-search-line align-bottom me-1"></i> Modifica marca (mandante)</template>
    <template v-if="open==true"><span class="text-red"><i class="ri-close-line align-bottom me-1 "></i> Chiudi modifica marca</span></template>
  </a>
  <br>
  <template v-if="open">
  <div class="mb-50">
    <form action="#" id="addform">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="mb-3">
                    <input
                        type="text"
                        id="search"
                        v-model="qSearch"
                        placeholder="Digita qui termine di ricerca..."
                        class="form-control-search p-3 mb-0.5 w-full border border-blue-300 rounded"
                        @input="fullSearch2()"
                    >             
                </div>
             </div>
            <div class="col-md-12 col-lg-12">
                <div class="mb-3">
                    <ul v-if="resultsList.length" class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10">
                      <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                        <b>{{ resultsList.length }}</b>
                        <template v-if="resultsList.length <= 1">
                          risultato
                        </template>
                        <template v-else>
                          risultati
                        </template>
                      </li>
                      <li
                        v-for="item in resultsList"
                        :key="item._id"
                        @click="selectItem(item), $emit('getParent')"
                        class="cursor-pointer hover:bg-gray-100 p-1"
                        >
                          {{ item.name }}   
                        </li>
                      </ul>
                </div>
            </div>
        </div>
        <div class="row mt-20">
            <div class="col-md-12 col-lg-12">
                <div class="mb-3">
                                       
                </div>
             </div>
        </div>
            
    </form>
  </div>
  </template>
</template>



