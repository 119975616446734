<script>
import { ref } from 'vue'
//import axios from 'axios';
import serverConfig   from '@/helpers/config';
//import DropZone from "@/components/widgets/dropZone";
import Swal from "sweetalert2";
import axios from 'axios';
//import { ref } from "vue";
import { bucket } from '@/firebase'
import detailFile from "@/components/widgets/detailFile";
//import { relativeTimeRounding } from 'moment';
import Camera from "simple-vue-camera";

export default {
  name: "documentManager",
  props: [
    'projectID',
    'productID',
    'productCode',
    'productEan',
    'url',
    'customerID',
    'data',
    'bucket',
    'type',
    'fileType',
    'fileAccept',
    'title',
  ],
  setup() {
    const fbbucket = bucket
    let base64 = false
    let tk = 'Bearer '+localStorage.getItem('tk')
          // Get a reference of the component
          const camera = ref(Camera);
        // Use camera reference to call functions
        const snapshot = async () => {
            let baseData = false
            const blob = await camera.value?.snapshot(
            { width: 1920, height: 1080 },
            "image/png",
            0.5);
            // To show the screenshot with an image tag, create a url
            const url = URL.createObjectURL(blob);
            var reader = new FileReader();
            baseData = reader.readAsDataURL(blob);
            //reader.readAsDataURL(blob);
            /*
            reader.onloadend = async function () {
               var base64data = reader.result;
               //console.log(base64data)
               /*
               var body = {
                buffer:base64data
               }
               await axios.post(`${serverConfig.EP}storage/snapshot`, body, {headers: { authorization: 'Bearer '+localStorage.getItem('tk')}})
               */
              // baseData = base64data
            
             // }
            
            console.log(url)
            console.log(baseData)
           
        }
    return { 
      tk, 
      fbbucket,
      camera,
      snapshot,
      base64,
    };
  },
  data() {
	return {
          UrlServer: serverConfig.EP,
          datastore: this.$store.state,
          page: 1,
          perPage: 10,
          sortRes:'desc',
          value: 'All',
          pages: [],
          paginated:{},
          select:'all',
          search:'',
          fileList:[],
          pathList:[],
          selectID:'',
          fileStorage:[],
          uploadValue:0,
         
          fileData:[],
          file:'',
          n_results:0,
          qSearch:'',
          resultsList:[],
          showImportFileMod:false,
          cameraDiv:false,
      }
	},
	computed:{ 
		
	},
  mounted(){
    this.getList(this.projectID)
    //this.getFilePath()
  },
	methods:{
    len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
    roundTo(value, decimalpositions)
    {
      var i = value * Math.pow(10,decimalpositions);
      i = Math.round(i);
      return i / Math.pow(10,decimalpositions);
    },
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    previewFile(event) {
      this.uploadValue=0;
      this.fileList=null;
      this.fileData = event.target.files;
      this.onUpload()
    },
    previewFileImport(event) {
      this.uploadValue=0;
      this.fileList=null;
      this.fileData = event.target.files;
      this.onUploadToServer()
    },
    async onUpload(){
      const formData = new FormData()
      
      formData.append('bucket', this.bucket)
      formData.append('projectID', this.projectID)
      if (this.data.customerDetails) {
        if (this.data.customerDetails._id) {
          formData.append('customerID', this.data.customerDetails._id)
        }
        if (this.data.customerDetails.ragSoc) {
          let name = `${this.data.customerDetails.ragSoc} `
          if (this.data.customerDetails.nome) {
            name += ` ${this.data.customerDetails.nome} `
          }
          if (this.data.customerDetails.cognome) {
            name += ` ${this.data.customerDetails.cognome} `
          }
          formData.append('customerName', name)
        }
      }
      
      if (this.datastore) {
        if (this.datastore.auth.currentUser) {
          let user =this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          if (this.datastore.auth.currentUser.email) {
            user += ` ${this.datastore.auth.currentUser.email} `
          }
          formData.append('authorTags', user)
          let author = {}
          author.uid = this.datastore.auth.currentUser.uid
          author.name = this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          author.email = this.datastore.auth.currentUser.email

          formData.append('author', author)
        }
      }

      for (const i of Object.keys(this.fileData)) {
        formData.append('files', this.fileData[i])
      }
      console.log(formData.files)
      this.axiosInterceptor()
      axios.post(this.UrlServer+'storage/multi-upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
      })
      .then((res) => {
        if(res){
          if (res.status==200) {
            this.getList(this.projectID)
          }
        }
      })
    },
    async onUploadToServer(){
      const formData = new FormData()
      
      formData.append('bucket', 'public/data')
      formData.append('projectID', this.projectID)
      formData.append('productID', this.productID)

     
      if (this.datastore) {
        if (this.datastore.auth.currentUser) {
          let user =this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          if (this.datastore.auth.currentUser.email) {
            user += ` ${this.datastore.auth.currentUser.email} `
          }
          formData.append('authorTags', user)
          let author = {}
          author.uid = this.datastore.auth.currentUser.uid
          author.name = this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          author.email = this.datastore.auth.currentUser.email
          author.id = this.datastore.auth.currentUser.id
          formData.append('author', author)
          formData.append('createdBy', author)
        }
      }

      for (const i of Object.keys(this.fileData)) {
        formData.append('files', this.fileData[i])
      }
      console.log(formData.files)
      this.axiosInterceptor()
      axios.post(this.UrlServer+'api/upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        
      })
      .then(() => {
        alert('caricati')
        
      })
    },
    postSnapshot(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}storage/snapshot`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          console.log(response.data)
          alert('SNAPSHOT CARICATA!')
          
        }
      })
    },
    replName(val,el){
      if (val.includes(el)){
        return val.replaceAll(el,' ')
      } else {
        return val
      }
    },
    formatKb(val){
      if (val){
        return this.roundTo(val / 1024, 1)
      } else {
        return ''
      }
    },
    filterList(id){
      let file = this.pathList.filter(function(el){
        return el._id == id
      })
      if (file){
        return file[0].url
      } else {
        return ''
      }
   
    },
    fileTypeCheck(file){
      if (file){
        if (file.split('/')[0] == 'image'){
          return 'image'
        } else {
          return file
        }
      
      }
    },
    getParent(){
      this.getList(this.projectID)
    },
    getList(value){
     
      this.axiosInterceptor()
      axios.get(this.url, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
            selectId: value,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{

        this.fileList  = response.data.results.data
        this.n_results = response.data.n_results
        this.paginated = response.data.results
        //this.$emit('getParent')
      })
    },
    getPathList(data) {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/db/by/file-path-list`, 
        { 
          params: { 
            fileList: data,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
          this.fileList  = data
          this.pathList  = response.data
        })
    },
     getFilePath(filePath,index) {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/f/db/to/gcloud/get-signed-url`, 
        { 
          params: { 
            filepath: filePath,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{

          if (response.data){
            this.fileList[index].resp = response.data
          } else {
            this.fileList[index].resp = false
          }
        
        })
    },
    deletedata(id,data){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              self.axiosInterceptor()
              axios.get(`${self.UrlServer}storage/f/db/to/gcloud/delete`, 
                { 
                  params: {
                    fileId: id,
                    filepath: data,
                  }, 
                  headers: { 'authorization':self.tk}
                }
              ).then(response=>{
                 if (response.status==200) {
                      Swal.fire({  
                      title: "File rimosso" , 
                      text: "Il file è stato rimosso con successo!", 
                      icon:"success",              
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000
                    })
                    self.getList(self.projectID)
                 }

              })
            }
        });


    },
    async getFileUrl(path){
      this.axiosInterceptor()
      let url = await axios.get(`${this.UrlServer}storage/download`, 
        { 
          params: { 
            filepath: path,
           
          }, 
          headers: { 'authorization':this.tk}
        })
        
        console.log(url.data)
        return url.data 
    },
    paginateAct(pg){
      this.page = pg
      this.getList(this.projectID)
    },   
	},
  components: {
    //DropZone,
    detailFile,
  },
};
</script>
<template>
  <div class="back-grey">
    <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">{{title}}</h5>
          </div>
          <div class="card-body">
            <div class="row mb-20">
              <div class="col-md-3 col-lg-12 text-left mb-2 mt-2">
              <h5>Directory:</h5>
              
              <b-alert show  variant="secondary" class="alert-top-border fade show" role="alert">
                <i class="bx bx-folder me-3 align-middle fs-16 text-secondary"></i><strong>/{{ bucket }}</strong>
              </b-alert>
              </div>
            </div>
            <div class="row ">
              <div class="col-md-3 col-lg-12 text-left mb-2 mt-30">
                <template v-if="cameraDiv==false">
                  <button class="btn btn-info" type="button" @click="cameraDiv=true"><span class="bx bx-plus"></span><i class="bx bx-camera"></i> Attiva fotocamera</button>
                </template>
                <template v-else>
                  <button class="btn btn-light" type="button" @click="cameraDiv=false"><span class="bx bx-x"></span><i class="bx bx-camera"></i> Chiudi fotocamera</button>
                </template>
                
              </div>
              <div class="col-md-9 col-lg-12 text-left mb-2 mt-2"></div>
            </div>
            <div class="row mb-2" v-if="cameraDiv==true">
              <div class="col-12 mb-2 mt-2 text-center">
                <camera :resolution="{ width: 375, height: 212 }" ref="camera" autoplay></camera>
                <button class="btn btn-primary " @click="snapshot"><i class="bx bx-camera"></i> Scatta foto</button>
              </div>
            </div>
            <div class="row mb-10 mt-10">
              <div class="col-12 mb-2 mt-2 text-center">
               
              </div>
            </div>
            <div class="row mt-10">
              <div class="" v-if="showImportFileMod==false">
                <p class="text-muted">Aggiungi allegati</p>
                <div class="dropzone position-relative">
                  <div class="mb-1">
                    <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
                  </div><h5>Carica file</h5>
                  <label for="dropzoneFile" class="bg-light text-dark stretched-link">Clicca sopra</label>
                  <input class="dropzoneFile btn btn-primary" id="dropzoneFile" type="file" :accept="fileAccept"  multiple="multiple" @change="previewFile" />
                
                </div>
              </div>
         <template v-if="type=='importazioni'">
              <div
                class="form-check form-switch form-switch-right form-switch-md mt-50 background-btn-grey-1"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Carica file per importazione</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  v-model="showImportFileMod"
                />
              </div>
              <div class=" pl-20 pr-20 pt-20 pb-20" v-if="showImportFileMod">
                <div class="row background-btn-grey-1  mb-50 mt-20 pl-10" >
                  <div class="col-md-12 col-lg-12">
                    <input class="form-control" id="multiFileListImport" type="file" :accept="fileAccept"  multiple="multiple" @change="previewFileImport" />

                  </div>
                </div>
              </div>
          </template>
          <div class="mt-50 pl-20 pr-20">
            
            <div class="row background-btn-grey-1  mb-50 mt-20 pl-10" >
            <div class="col-md-12 col-lg-12">
                
                <div class="mb-3 form-icon">
                    
                    <input
                        type="text"
                        id="search"
                        v-model="search"
                        placeholder="Digita qui termine di ricerca ..."
                        class="form-control-search p-3 mt-20 mb-0.5 w-full border border-blue-300 rounded"
                        @input="getList(projectID)"
                    >                        
                              
                </div>
            </div>
        </div>

            <div class="card-header  mb-50">
              <div class="row align-items-center mb-50">
               
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Tutti
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
            <div class="table-card mb-1">
                <div class="row thRow  ">

                    <div class="col text-left">
                        File
                    </div>
                    <div class="col text-left">
                      Nome originale del file
                    </div>
                    <div class="col text-left">
                      Tipo di documento
                    </div>
                    <div class="col text-left">
                      Tipo di file
                    </div>
                    <div class="col text-left">
                      Dimensione (Kb)
                    </div>
                    <div class="col text-left">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of fileList" :key="index">

                    <div class="col text-left">
                      <template v-if="fileTypeCheck(item.mimetype)=='image'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img :src="fileList[index].resp" alt="" class="rounded avatar-sm shadow" /></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.ms-excel'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><b-img src="@/assets/images/modello-excel.png" alt="" class="rounded avatar-sm shadow"></b-img></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                            <template v-if="fileList[index].resp">
                              <a :href="fileList[index].resp"><img src="@/assets/images/modello-xls.png" alt="" class="rounded avatar-sm shadow"/></a>
                              <br><a :href="fileList[index].resp">Scarica</a>
                            </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/msword'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                            <template v-if="fileList[index].resp">
                              <a :href="fileList[index].resp"><img src="@/assets/images/modello-docx.png" alt="" class="rounded avatar-sm shadow"/> </a>
                              <br><a :href="fileList[index].resp">Scarica</a>
                            </template>
                          </template>
                        </template>                       
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-docx.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>       
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/pdf'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-pdf.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template> 
                        <template v-else>
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-download.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>   
                       
                    </div>
                    <div class="col text-left">
                      {{ item.originalname }}
                    </div>
                    <div class="col text-left">
                      <template v-if="item.tag_name">
                          {{ replName(item.tag_name,'_') }}
                        </template>
                    </div>
                    <div class="col text-left">
                      {{ item.mimetype }}
                    </div>
                    <div class="col text-left">
                      {{ formatKb(item.size) }}
                    </div>
                    <div class="col text-center ">
                      <ul class="list-inline hstack text-center gap-2 mb-0">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Modifica">   
                                         
                            <detailFile :id="item._id" :data="item" :projectID="projectID" :type="type" :url="fileList[index].resp"  />
                          </li>
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Rimuovi">
                           
                            <a class="text-danger d-inline-block remove-item-btn" href="javascript:void(0)" @click="deletedata(item._id, item.path)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
          </div>
<!--
              <div class="table-responsive table-card mb-1">
                <table class="table align-middle" id="customerTable">
                  <thead class="table-light text-muted">
                    <tr>
                      <th class="sort" data-sort="filepath">File</th>
                      <th class="sort" data-sort="originalname">Nome originale del file</th>
                      <th class="sort" data-sort="tag_name">Tipo di documento</th>
                      <th class="sort" data-sort="mimetype">Tipo di file</th>
                      <th class="sort" data-sort="size">Dimensione (Kb)</th>

                      <th class="sort" data-sort="action">Azioni</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of fileList" :key="index" >
                      <td class="id " style="display: none">
                        <a href="javascript:void(0);" class="fw-medium link-primary">{{ item._id }}</a>
                        
                      </td>
                      
                      <td class="originalname pl-5">
                        <template v-if="fileTypeCheck(item.mimetype)=='image'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img :src="fileList[index].resp" alt="" class="rounded avatar-sm shadow" /></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.ms-excel'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><b-img src="@/assets/images/modello-excel.png" alt="" class="rounded avatar-sm shadow"></b-img></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                            <template v-if="fileList[index].resp">
                              <a :href="fileList[index].resp"><img src="@/assets/images/modello-xls.png" alt="" class="rounded avatar-sm shadow"/></a>
                              <br><a :href="fileList[index].resp">Scarica</a>
                            </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/msword'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                            <template v-if="fileList[index].resp">
                              <a :href="fileList[index].resp"><img src="@/assets/images/modello-docx.png" alt="" class="rounded avatar-sm shadow"/> </a>
                              <br><a :href="fileList[index].resp">Scarica</a>
                            </template>
                          </template>
                        </template>                       
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-docx.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>       
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/pdf'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-pdf.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template> 
                        <template v-else>
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-download.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>                                                 
                      </td>                      

                      <td class="originalname pl-5">
                        {{ item.originalname }}
                      </td>
                      <td class="tag_name">
                        <template v-if="item.tag_name">
                          {{ replName(item.tag_name,'_') }}
                        </template>
                      </td>
                      
                      <td class="mimetype">{{ item.mimetype }}</td>
                      <td class="size">{{ formatKb(item.size) }}</td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Modifica">   
                                         
                            <detailFile :id="item._id" :data="item" :projectID="projectID" :type="type" :url="fileList[index].resp"  />
                          </li>
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Rimuovi">
                           
                            <a class="text-danger d-inline-block remove-item-btn" href="javascript:void(0)" @click="deletedata(item._id, item.path)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>-->
              <div class="d-flex justify-content-end mt-3" v-if="n_results>perPage">
                <label class="pt-2">Risultati per pagina:</label>
                <div class="pl-10 pr-20">
               
                <select class="form-control" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                  <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                </select>
                </div>

                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                    Precedenti
                  </a>
                  <ul class="pagination listjs-pagination mb-0" >
                    <li :class="{
                              active: index == page,
                              disabled: index == '...',
                            }" v-for="index in paginated.total_pages" :key="index">
                         <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                    </li>
                  
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                   Successivi
                  </a>
                </div>
                

              </div>
            
            </div>
          <!-- / Table -->
        </div>
      </div>
    </div>
  
  </div>
</template>